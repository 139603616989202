export const ADD_POINT_PANEL_ANIMATION_TIME = 500;
export const ADD_POINT_PANEL_WIDTH = 394;

export const ADDRESS_BAR_WDITH = 250;

export const MOBILE_WIDTH_TRESHOLD = 768;

export const NOT_PROTECTED_URLS = [
    "register", 
    "terms-of-service", 
    "privacy", 
    "activate",
    "password-reset",
    "password-reset-request"];

export const DOMAIN = "spaceshout.com";

export const INITIAL_DEFAULT_LOADING_TIME = 300;

export const ARROW_UP_CODE = 40;
export const ARROW_DOWN_CODE = 38;
export const ENTER_CODE = 13;
export const POST_SEARCH_ZOOM_LEVEL = 18;
export const COLLECTION_ON_MAP_OFFSET_IN_PX = 20;


export const X_SMALL_IMAGE_PATH = 'https://res.cloudinary.com/spaceshout/image/upload/t_xsmall/';
export const SMALL_IMAGE_PATH = 'https://res.cloudinary.com/spaceshout/image/upload/t_small/';
export const MEDIUM_IMAGE_PATH = 'https://res.cloudinary.com/spaceshout/image/upload/t_medium/';
export const FULL_IMAGE_PATH = 'https://res.cloudinary.com/spaceshout/image/upload/t_full/';


export const APPLE_ID_URL = 'https://apps.apple.com/app/id=6475599807'
export const ANDROID_ID_URL = 'https://play.google.com/store/apps/details?id=com.spaceshout';
