export enum SpaceshoutSize {
    xsmall = 'xsmall',
    small = 'small',
    medium = 'medium',
    large = 'large',
    full = 'full',
    rounded = 'rounded',
    roundedSmall = 'roundedSmall',
    roundedMedium = 'roundedMedium'
}
