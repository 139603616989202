import { Injectable } from '@angular/core';
import { LatLng } from 'app_code/app/shared/interfaces/latLng';
import { BehaviorSubject, Subject } from 'rxjs';

interface AddressAndPoint {
    address: string,
    point: LatLng
}

@Injectable()
export class AddressSelectService {

    public inputPoint: BehaviorSubject<AddressAndPoint> = new BehaviorSubject<AddressAndPoint>(null);
    public outputPoint: Subject<AddressAndPoint> = new Subject<AddressAndPoint>();


}