import { InjectableRxStompConfig } from '@stomp/ng2-stompjs';
import * as SockJS from "sockjs-client";
import { environment } from '../../../../environments/environment';

export const myRxStompConfig : InjectableRxStompConfig = {
  
    // Which server?
    webSocketFactory: getSocketProvider(), 
    
    // Headers
    // Typical keys: login, passcode, host
    connectHeaders: {
    //   Authorization: "Bearer " + localStorage.getItem("id_token")
    },

    // How often to heartbeat?
    // Interval in milliseconds, set to 0 to disable
    heartbeatIncoming: 50000, // Typical value 0 - disabled
    heartbeatOutgoing: 50000, // Typical value 20000 - every 20 seconds

    // Wait in milliseconds before attempting auto reconnect
    // Set to 0 to disable
    // Typical value 5000 (5 seconds)
    reconnectDelay: 10000,

    // Will log diagnostics on console
    // It can be quite verbose, not recommended in production
    // Skip this key to stop logging to console
    debug: (msg: string): void => {
        if(!environment.production) {
            console.log(new Date(), msg);
        }
    }
}

export function getSocketProvider() {
    return () => {
        let url: string = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/sockets";

        if(window.location.hostname == "localhost") {
            url = window.location.protocol + "//" + window.location.hostname + ":" + "8080" + "/sockets";
        }

        return new SockJS(url);
    }
        
}