import { LatLng } from '../../../shared/interfaces/latLng';
import { Injectable, signal } from '@angular/core';
import { ReplaySubject, Subject, of } from 'rxjs';
import { Map } from 'leaflet';
import { Area } from 'app_code/app/shared/interfaces/area';
import * as L from 'leaflet';
import { Log } from 'ng2-logger/browser';
import { MapLayer } from 'app_code/app/shared/interfaces/map-layer.enum';
import { SearchCriteria } from 'app_code/app/shared/interfaces/comment-search-criteria';
import { SearchType } from 'app_code/app/shared/interfaces/search-type.enum';
import { NavigationEnd, Router } from '@angular/router';
import { StoreDataService } from 'app_code/app/shared/services/store/store-data.service';
import { filter, switchMap } from 'rxjs/operators';
import { SearchMenuService } from '../../search-panel/services/search-menu.service';
import { ADDRESS_BAR_WDITH, ADD_POINT_PANEL_ANIMATION_TIME, ADD_POINT_PANEL_WIDTH, MOBILE_WIDTH_TRESHOLD } from 'app_code/app/shared/constants';
import { MapButtonsMode } from '../model/map-buttons-mode';
import { LocalStorageService } from 'app_code/app/shared/services/local-storage/local-storage.service';
import { LocalStorageKey } from 'app_code/app/shared/model/local-storage-key';

@Injectable()
export class MapService {

  map: Map;
  mapReady$ = new ReplaySubject<void>(1);

  searchCriteria: SearchCriteria = {
    searchType: SearchType.COLLECTION,
    selectedItem: null,
    hidePublic: false
  };

  isNextPage = false;
  isPreviousPage = false;
  zoomInEnabled = false;
  zoomOutEnabled = false;

  markers: L.LayerGroup;
  
  addPointMarkers: L.LayerGroup = null;
  selectedAddress = '';
  isLocationHintVisible = false;
  isSubscribedAreaHintVisible = false;
  isCollectionHintVisible = false;
  isCollectionHintTemporaryHidden = false;
  collectionHintOpenedByRoute: string;
  addressBarPosition: L.Point;
  canUpdateMarkersOnMap = false; 

  area$ = new Subject<Area>();

  requestCurrentLocaiton = new Subject<void>();
  receiveCurrentLocation = new Subject<L.LatLng>();
  selectedAddressChanged$ = new ReplaySubject<void>(1);

  mapButtonsMode = signal(MapButtonsMode.ALL);

  private _ownedAndMineFilter = false;
  private POSITION_KEY = 'POSITION_KEY';
  private ZOOM_KEY = 'ZOOM_KEY';
  private log = Log.create('MapService');

  get hasAddPointMarker(): boolean {
    return this.addPointMarkers && this.addPointMarkers.getLayers()?.length > 0;
  }

  get hasPointMarker(): boolean {
    return this.markers && this.markers.getLayers()?.length > 0;
  }

  get isMapDisabled(): boolean {
    return !this.map.dragging.enabled();
  }

  get isMobile(): boolean {
    return window.innerWidth < MOBILE_WIDTH_TRESHOLD;
  }

  get ownedAndMineFilter(): boolean {
    return this._ownedAndMineFilter;
  }

  constructor(
    private router: Router,
    private storeDataService: StoreDataService,
    private searchMenuService: SearchMenuService,
    private localStorageService: LocalStorageService
  ) {
      this.initializeOwnedAndMineFilter();
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
      ).subscribe((event: NavigationEnd) => {
        if (!this.map) {
          return;
        }
        this.mapEnable();
        if (
          (event.urlAfterRedirects.includes('/add-point') && !this.hasAddPointMarker) ||
          (event.urlAfterRedirects.includes('/edit-point') && !this.hasAddPointMarker)
        ) {
          this.clearMapLayers(MapLayer.MARKER);
          this.clearMapLayers(MapLayer.POLYLINE);
          this.setMarkerOnMap();
          this.enterNewPointAddingMode();
        } else if (
          (event.urlAfterRedirects.includes('/add-point') && this.hasAddPointMarker) ||
          (event.urlAfterRedirects.includes('/edit-point') && this.hasAddPointMarker)
        ) {
          this.addPointMarkers?.clearLayers();
          this.setMarkerOnMap();
          this.enterNewPointAddingMode();
        } else if (event.urlAfterRedirects.includes('/search')) {
          this.mapDisable();
        } else if (this.mapButtonsMode() === MapButtonsMode.POINT_ADDING){
          this.exitNewPointAddingMode();
        } else if (this.map && this.addPointMarkers) {      
          this.addPointMarkers?.clearLayers();
          this.addPointMarkers = null;
          this.isLocationHintVisible = false;
          this.map.invalidateSize(true);
        }
      });
  
      this.storeDataService.selectedPoint.pipe(
        filter((val) => val.point !== null || val.backup !== null),
        switchMap((selectedPoint) => {
          if (selectedPoint?.text) {
            this.selectedAddress = selectedPoint.text;
            this.selectedAddressChanged$.next();
            return of(null);
          }
          const point = selectedPoint?.point?.lat && selectedPoint?.point?.lng ? selectedPoint.point : selectedPoint.backup;
          return this.searchMenuService.reverseGeocoding(point.lat, point.lng);
        })
      ).subscribe((result) => {
        if (result === null) {
          return;
        }
        this.selectedAddress = result[0];
        this.selectedAddressChanged$.next();
      });

      this.storeDataService.selectedAddressOverwriteLabel.pipe(
      ).subscribe((label) => {
        this.selectedAddress = label;
        this.selectedAddressChanged$.next();
      });

      this.storeDataService.selectedAddressOverwritePanel.subscribe((val) => val ? this.mapDisable() : this.mapEnable());
  }

  getSavedPosition(): LatLng {
    let latLng: string = localStorage.getItem(this.POSITION_KEY);
    if (latLng) {
      return JSON.parse(latLng);
    } else {
      return { lat: 12.4386827, lng: 10.8984375 };
    }
  }

  getSavedZoom(): number {
    let zoom: string = localStorage.getItem(this.ZOOM_KEY);
    if (zoom) {
      return +zoom
    }
    return 4;
  }

  savePosition(lat: number, lng: number, zoom?: number) {
    if (lat && lng) {
      let latLng = new LatLng();
      latLng.lat = lat;
      latLng.lng = lng;
      localStorage.setItem(this.POSITION_KEY, JSON.stringify(latLng));
    }
    if (zoom) {
      localStorage.setItem(this.ZOOM_KEY, String(zoom));
    }
  }

  getCurrentArea(): Area {
    // Center point in px
    const mapContainer = this.map.getSize();
    const center = this.map.latLngToContainerPoint(this.map.getCenter());
  
    const offsetX = 20;
    const offsetY = 70;
  
    // New corner points in px taking offset into consideration
    const newNorthEastPoint = L.point(center.x + (mapContainer.x / 2) - offsetX, center.y - (mapContainer.y / 2) + offsetY);
    const newSouthWestPoint = L.point(center.x - (mapContainer.x / 2) + offsetX, center.y + (mapContainer.y / 2));
  
    // Converting px to lat/lng
    const newNorthEast = this.map.containerPointToLatLng(newNorthEastPoint);
    const newSouthWest = this.map.containerPointToLatLng(newSouthWestPoint);
  
    return {
      ne: {
        lat: newNorthEast.lat,
        lng: newNorthEast.lng
      },
      sw: {
        lat: newSouthWest.lat,
        lng: newSouthWest.lng
      }
    };
  }

  zoomIn(): void {
    if (!this.isMaxZoomIn()) {
      this.mapArrowsDisable();
      this.clearMapLayers(MapLayer.POLYLINE);
      this.map.zoomIn();
    }
    this.setZoomButtons();
  }

  zoomOut(): void {
    if (!this.isMaxZoomOut()) {
      this.mapArrowsDisable();
      this.clearMapLayers(MapLayer.POLYLINE);
      this.map.zoomOut();
    }
    this.setZoomButtons();
  }

  setLocationBounds(ne: LatLng, sw: LatLng) {
    if (ne?.lat && ne?.lng && sw?.lat && sw?.lng) {
      this.map.fitBounds([[ne.lat, ne.lng], [sw.lat, sw.lng]]);
    }
  }

  setZoomButtons(): void {
    this.log.info('selected ZOOM: ', this.map.getZoom());
    this.zoomInEnabled = !this.isMaxZoomIn();
    this.zoomOutEnabled = !this.isMaxZoomOut();
  }

  isMaxZoomIn(): boolean {
    return this.map.getZoom() >= this.map.getMaxZoom();
  }

  isMaxZoomOut(): boolean {
    return this.map.getZoom() <= this.map.getMinZoom()
  }

  mapDisable(): void {
    this.map.dragging.disable();
    this.map.touchZoom.disable();
    this.map.doubleClickZoom.disable();
    this.map.scrollWheelZoom.disable();
    this.map.boxZoom.disable();
    this.map.keyboard.disable();
    if (this.map.tap) {
      this.map.tap.disable();
    }

    if (this.hasAddPointMarker) {
      this.toggleAddPointMarker(false);
    }
  }

  mapEnable(): void {
    if (this.map) {
      this.map.dragging.enable();
      this.map.touchZoom.enable();
      this.map.doubleClickZoom.enable();
    this.map.scrollWheelZoom.enable();
      this.map.boxZoom.enable();
      this.map.keyboard.enable();
      if (this.map.tap) {
        this.map.tap.enable();
      }
    }

    if (this.hasAddPointMarker) {
      this.toggleAddPointMarker(true);
    }
  }

  enterNewPointAddingMode(): void {
    this.clearMapLayers(MapLayer.MARKER);
    this.clearMapLayers(MapLayer.POLYLINE);
    this.mapButtonsMode.set(MapButtonsMode.POINT_ADDING);
    this.storeDataService.setToggleLeftSideBar(false);
  }

  exitNewPointAddingMode(): void {
    this.addPointMarkers?.clearLayers();
    this.addPointMarkers = null;
    this.mapButtonsMode.set(MapButtonsMode.ALL);
  }

  mapArrowsDisable(): void {
    this.isNextPage = false;
    this.isPreviousPage = false;
  }

  clearMapLayers(type: MapLayer): void {
    this.log.info('clearMapLayers:', type);
    let layerClass: any;

    switch (type) {
      case MapLayer.POLYLINE:
        layerClass = L.Polyline;
        break;
      case MapLayer.POLYGON:
        layerClass = L.Polygon;
        break;
      case MapLayer.MARKER:
        this.markers.clearLayers();
        return;
      default:
        return;
    }

    this.map.eachLayer(layer => {
      if (layer instanceof layerClass) {
        this.map.removeLayer(layer);
      }
    });
  }

  openPointAddingPanel(lat: number, lng: number) {
    this.saveSelectedPoint(lat, lng);
    if (this.router.url === '/add-point') {
      this.setMarkerOnMap();
    } else {
      this.router.navigate(['add-point']);
    }
  }

  saveSelectedPoint(lat: number, lng: number) {
    const point = {
      point: {
        lat: lat,
        lng: lng
      },
      backup: {
        lat: this.map.getCenter().lat,
        lng: this.map.getCenter().lng
      }
    }
    this.storeDataService.setSelectedPoint(point);
  }

  toggleAddPointMarker(toggleValue: boolean): void {
    const newIcon = L.icon({
      iconUrl: toggleValue ? 'assets/marker.svg' : 'assets/marker_disabled.svg'
    });
    this.addPointMarkers.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        layer.setIcon(newIcon);
      }
    });
  }

  setMarkerOnMap(zoomLevel?: number): void {
    const markerHeight = 50;
    const markerWidth = 53;
    const latLng =  {
      lat: this.storeDataService.selectedPoint.value.point.lat,
      lng: this.storeDataService.selectedPoint.value.point.lng
    };

    this.map.invalidateSize(); // Necessary to adjust Leaflet's internal size tracking
    if (zoomLevel) {
      this.map.setView(latLng, zoomLevel, {
        animate: true, 
        duration: 1
      });
    }
    setTimeout(() => {
      var myCustomIcon = L.icon({
        iconUrl: 'assets/marker.svg',
        iconSize: [markerHeight, markerWidth], // Size of the icon
        iconAnchor: [markerHeight / 2, markerWidth], // Anchors the icon at the bottom center
        popupAnchor: [0, -markerWidth] // Adjusts the popup to open above the icon
      });

      this.addPointMarkers = L.layerGroup().addTo(this.map);
      L.marker(
        latLng,
        { 
          icon: myCustomIcon
        }
      ).addTo(this.addPointMarkers);

      this.centerMapTakingPanelIntoAccount({lat: this.storeDataService.selectedPoint.value.point.lat, lng: this.storeDataService.selectedPoint.value.point.lng}, this.map);
      this.centerMapAddressBar(markerWidth, markerHeight);

      this.isLocationHintVisible = this.localStorageService.getItem(LocalStorageKey.MAP_LOCATION_HINT_VISIBLE) ? false : true;
    }, ADD_POINT_PANEL_ANIMATION_TIME);
  }

  updateMarkerToMapCenter(): void {
    this.addPointMarkers.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        const mapSize = this.map.getSize(); // Gets the current size of the map container (width and height in pixels)
        let offsetX, offsetY;
        if (this.isMobile) {
          const panelHeightOffset = (window.innerHeight * 0.6) / 2; // 60% of the panel's height divided by 2 to adjust the center
          offsetX = mapSize.x / 2; // X remains the same
          offsetY = mapSize.y / 2 - panelHeightOffset; // Adjust Y to get the new center
        } else {
          const panelWidthOffset = ADD_POINT_PANEL_WIDTH / 2; // Half of the panel's width to adjust the center
          offsetX = mapSize.x / 2 - panelWidthOffset; // Adjust X to get the new center
          offsetY = mapSize.y / 2; // Y remains the same
        }
        const containerPoint = L.point(offsetX, offsetY); // Create a point with the new center
        const latLng = this.map.containerPointToLatLng(containerPoint); // Convert point to LatLng
        (layer as any).setLatLng(latLng);
      }
    });
  }

  getVisibleCenter() {
    const centerPoint = this.map.latLngToContainerPoint(this.map.getCenter());
    if (this.isMobile) {
      // Calculate the shift needed to center in the visible area
      const yShift = (this.map.getSize().y * (60 / 2)) / 100; // 60% of the panel's height divided by 2 to adjust the center
      // Shift the center upwards by half the panel's height percentage
      const adjustedCenterPoint = L.point([centerPoint.x, centerPoint.y - yShift]);
      return this.map.containerPointToLatLng(adjustedCenterPoint);
    } else {
      const panelWidthPx = ADD_POINT_PANEL_WIDTH; // Panel width in pixels
      const xShift = this.map.getSize().x / 2 - panelWidthPx;
      const adjustedCenterPoint = L.point([centerPoint.x - xShift, centerPoint.y]);
      return this.map.containerPointToLatLng(adjustedCenterPoint);
    }
  }

  getPointSizeInMeters(): number {
    const pointSize = { width: 80, height: 120 };
    const latLngCorners = this.convertContainerSizeToLatLng(pointSize.width, pointSize.height);

    const distanceX = this.map.distance(latLngCorners.northWest, latLngCorners.northEast);
    // const distanceY = this.map.distance(latLngCorners.northWest, latLngCorners.southWest); // TEMPORARY NOT NEEDED

    return distanceX;
  }

  getNumberOfPointsThatFitsCurrentView(): {horizontal: number, vertical: number} {
    const sizeImplicator = 2;
    const mapOffset = 80;
    const pointTotalSize = { width: 80 * sizeImplicator, height: 120 * sizeImplicator};
    const {innerHeight, innerWidth} = window;
    const retVal = {horizontal: 1, vertical: 1};

    if ((innerWidth - (mapOffset * 2)) > pointTotalSize.width) {
      retVal.horizontal = Math.floor(innerWidth / pointTotalSize.width);
    }

    if ((innerHeight - (mapOffset * 2)) > pointTotalSize.height) {
      retVal.vertical = Math.floor(innerHeight / pointTotalSize.height);
    }

    return retVal;
  }

  getBoundsExtendedByOffset(swlatLng, nelatLng, offsetInPixels: number): L.LatLngBounds {
    // Convert to pixels
    const swPoint = this.map.latLngToLayerPoint(swlatLng);
    const nePoint = this.map.latLngToLayerPoint(nelatLng);

    // Add offset
    const newSwPoint = L.point(swPoint.x - offsetInPixels, swPoint.y + offsetInPixels);
    const newNePoint = L.point(nePoint.x + offsetInPixels, nePoint.y - offsetInPixels);

    // convert back to latlng
    const newSwLatLng = this.map.layerPointToLatLng(newSwPoint);
    const newNeLatLng = this.map.layerPointToLatLng(newNePoint);
    return L.latLngBounds(newSwLatLng, newNeLatLng);
  }

  generateGoogleMapsLink(latitude: number, longitude: number): string {
    return `https://www.google.com/maps?q=${latitude},${longitude}`;
  }

  private convertContainerSizeToLatLng(widthPx, heightPx) {
    var center = this.map.getCenter();
    var pointC = this.map.latLngToContainerPoint(center);
    
    var pointX = L.point(pointC.x + widthPx / 2, pointC.y);

    var latLngX = this.map.containerPointToLatLng(pointX);

    return {
        northWest: this.map.containerPointToLatLng(L.point(pointC.x - widthPx / 2, pointC.y - heightPx / 2)),
        northEast: latLngX,
        // southWest: this.map.containerPointToLatLng(L.point(pointC.x - widthPx / 2, pointC.y + heightPx / 2)), // TEMPORARY NOT NEEDED
        // southEast: this.map.containerPointToLatLng(L.point(pointC.x + widthPx / 2, pointC.y + heightPx / 2)), // TEMPORARY NOT NEEDED
    };
}

  centerMapTakingPanelIntoAccount(latlng, map): void {
    let offsetX = 0, offsetY = 0;
    if (this.isMobile) {
      offsetY = (window.innerHeight * 0.6) / 2; // 60% of the window height divided by 2 to adjust the center
    } else {
      offsetX = ADD_POINT_PANEL_WIDTH / 2; // Half the panel width to adjust the centering
    }
    const point = map.latLngToContainerPoint(latlng);
    const newPoint = L.point(point.x + offsetX, point.y + offsetY);
    const newCenter = map.containerPointToLatLng(newPoint);

    const options =   {
      animate: true, 
      noMoveStart: true
    };

    map.panTo(newCenter, options);
  }

  setOwnedAndMineFilter(val: boolean): void {
    this._ownedAndMineFilter = val;
    this.localStorageService.setItem(LocalStorageKey.OWNED_AND_MINE_FILTER, this._ownedAndMineFilter.toString());
  }

  initializeOwnedAndMineFilter(): void {
    this._ownedAndMineFilter = this.localStorageService.getItem(LocalStorageKey.OWNED_AND_MINE_FILTER) ? true : false;
  }

  private centerMapAddressBar(markerWidth, markerHeight): void {
    // Get the geographical center of the map
    const centerLatLng = this.map.getCenter();
    // Convert the geographical center to pixel coordinates
    const centerPoint = this.map.latLngToContainerPoint(centerLatLng);
    const markerToAddressBarOffset = 12;

    if (this.isMobile) {
      centerPoint.x -= ADDRESS_BAR_WDITH / 2;
      centerPoint.y -= (window.innerHeight * 0.1) + ((window.innerHeight * 0.4) /2) - markerToAddressBarOffset;
    } else {
       centerPoint.x -= ADDRESS_BAR_WDITH + markerWidth + (markerWidth/2);
       centerPoint.y -= -markerToAddressBarOffset;
    }

    this.addressBarPosition = centerPoint; 
  }
}
