import { Log } from 'ng2-logger/browser';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Report } from '../../interfaces/report';
import { Reply } from 'app_code/app/shared/interfaces/reply';
import { PointContentDTO } from 'app_code/app/shared/model/point-dto';

@Injectable()
export class ReportService {

  private log = Log.create('ReportService');
  public reportModalClicked = new Subject<{comment: Reply | PointContentDTO, contentType: string}>();
  public usernameClicked = new Subject<string>();

    constructor(private authHttp: HttpClient) { }

    public openReportModal(comment: Reply | PointContentDTO, contentType: string) {
        this.reportModalClicked.next({comment, contentType});
    }

    public navigateToUser(userId: string) {
        this.usernameClicked.next(userId);
    }


    reportPoint(id: string, text: string): Observable<void> {
        return this.authHttp.post('rest/user/poi/report', {id, text})
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    reportReply(id: string, text: string): Observable<void> {
        return this.authHttp.post('rest/user/poi/reply/report', {id, text})
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }
    // SECTION: Methods used to report comments and replies

    public reportComment(reportedComment): Observable <void> {
        return this.authHttp.post("rest/user/poi/report", reportedComment)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError))
    }

    public reportCommentReply(reportedReply): Observable <void> {
        return this.authHttp.post("rest/user/poi/reply/report", reportedReply)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError))
    }

    // SECTION: Methods used to get list of all reported comments or replies

    public getCommentReportsOfAllUsers(page: number, size: number): Observable<any> {
        return this.authHttp.get("rest/poi/reports"  + "?page=" + page + "&size=" + 7)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError))
    }

    public getReplyReportsOfAllUsers(page: number, size: number): Observable<any> {
        return this.authHttp.get("rest/poi/reply/reports"  + "?page=" + page + "&size=" + 7)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError))
    }

        // SECTION: Methods used to get reports of an user

    public getReceivedCommentReportsByUserId(userId: string, page: number, size: number): Observable<any> {
        return this.authHttp.get("rest/user/" + userId + "/poi/received/reports" + "?page=" + page + "&size=" + size)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError))
    }

    public getGivenCommentReportsByUserId(userId: string, page: number, size: number): Observable<any> {
        return this.authHttp.get("rest/user/" + userId + "/poi/given/reports" + "?page=" + page + "&size=" + size)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError))
    }

    public getReceivedReplyReportsByUserId(userId: string, page: number, size: number): Observable<any> {
        return this.authHttp.get("rest/user/" + userId + "/poi/reply/received/reports" + "?page=" + page + "&size=" + size)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError))
    }

    public getGivenReplyReportsByUserId(userId: string, page: number, size: number): Observable<any> {
        return this.authHttp.get("rest/user/" + userId + "/poi/reply/given/reports" + "?page=" + page + "&size=" + size)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError))
    }

    // SECTION: Methods used to get number of times user has been reported or reported someone

    public getGivenReportsCountByUserId(userId: string): Observable<any> {
        let request1 = this.authHttp.get("rest/user/" + userId + "/poi/reply/count/given/reports")
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))

        let request2 = this.authHttp.get("rest/user/" + userId + "/poi/count/given/reports")
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))

        return forkJoin([request1, request2]);
    }

    public getReceivedReportsCountByUserId(userId: string): Observable<any> {
        let request1 = this.authHttp.get("rest/user/" + userId + "/poi/reply/count/received/reports")
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))

        let request2 = this.authHttp.get("rest/user/" + userId + "/poi/count/received/reports")
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))

        return forkJoin([request1, request2]);
    }

    // SECTION: Methods used to get list of users with highest report amount

    public getCommentReportedUsersOrderedByReportCount(page: number, size: number): Observable<any> {
        return this.authHttp.get("rest/user/poi/reported" + "?page=" + page + "&size=" + size )
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public getCommentReportersUsersOrderedByReportCount(page: number, size: number): Observable<any> {
        return this.authHttp.get("rest/user/poi/reporters" + "?page=" + page + "&size=" + size )
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public getReplyReportedUsersOrderedByReportCount(page: number, size: number): Observable<any> {
        return this.authHttp.get("rest/user/poi/reply/reported" + "?page=" + page + "&size=" + size )
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public getReplyReportersUsersOrderedByReportCount(page: number, size: number): Observable<any> {
        return this.authHttp.get("rest/user/poi/reply/reporters" + "?page=" + page + "&size=" + size )
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    // SECTION: Methods used to get list of users with highest accuracy/inaccuracy report score

    public getReportersUsersOrderedByAccurateReportScore(page: number, size: number): Observable<any> {
        return this.authHttp.get("rest/user/correct/report/scores" + "?page=" + page + "&size=" + size )
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public getReportersUsersOrderedByInccurateReportScore(page: number, size: number): Observable<any> {
        return this.authHttp.get("rest/user/incorrect/report/scores" + "?page=" + page + "&size=" + size )
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    // SECTION: Methods used to get list of all blocked users

    public getAllBlockedUsers(page: number, size: number): Observable<any> {
        return this.authHttp.get("rest/user/poi/blocked" + "?page=" + page + "&size=" + size )
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))

    }

    // SECTION: Methods used to solve report cases

    public banUserById(userId: number, commentId: number): Observable<any> {
        const userData = {
          userIdToBan: userId,
          commentIdToDelete: commentId
        }
        return this.authHttp.post("rest/user/" + userId + "/ban", null)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError))
    }

    public keepContentOfId(obj: object): Observable<any> {
        return this.authHttp.post("rest/user/ban/keep", obj)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public deleteReportById(obj: object): Observable<any> {
        return this.authHttp.post("rest/user/ban/remove", obj)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public unbanUserById(userId: string): Observable<any> {
        return this.authHttp.delete("rest/user/" + userId + "/ban")
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError))
    }

    // Utility

    public mergeReports(original: any, merge: Report[]): void {
        merge.forEach((report: any) => {
            let reportExists: boolean = original.some(el => {
                    return report.id == el.id
            });
            if (!reportExists) {
                original.push(report);
            }
        });
    }

    public mergeUsersList(original: any, merge: []): void {
        merge.forEach((user: any) => {
            let reportExists: boolean = original.some(el => {
                    return user.userId == el.userId
            });
            if (!reportExists) {
                original.push(user);
            }
        });
    }

    private handleError(error: any): Observable<any> {
        this.log.er(error);
        return throwError(() => new Error(error.message || 'Server error'));
    }

}
