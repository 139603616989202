import {Log} from 'ng2-logger/browser';
import {Injectable} from "@angular/core";
import {forkJoin, Observable, Subject, throwError} from "rxjs";
import {Chat} from "app_code/app/shared/interfaces/chat";
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import { NotificationDTO } from '../model/notification-dto';

@Injectable()
export class NotificationService {

    private log = Log.create('NotificationService');
    public messageSubject = new Subject<Chat>();

    constructor(private authHttp: HttpClient){}

    public getMessageSubject(): Observable<Chat> {
        return this.messageSubject.asObservable();
      }

      public sendMessage(chat: Chat): void {
        this.messageSubject.next(chat);
      }

      public getUserNotifications(): Observable<any> {
        let request1 = this.authHttp.get("rest/user/last/checked/notification")
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))

        let request2 = this.authHttp.get("rest/user/notifications"  + "?page=" + 0 + "&size=" + 10)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))

        return forkJoin([request1, request2]);
    }

    getNotifications(page: number, size: number): Observable<NotificationDTO> {
      return this.authHttp.get(`rest/user/notifications?page=${page}&size=${size}`)
      .pipe(
          map(res => {
              return res;
          }),
          catchError(this.handleError));
    }

    public notificationChecked(): Observable<any> {
      return this.authHttp.put("rest/user/notification/check", null)
        .pipe(
          map(res => {
              return res;
          }),
          catchError(this.handleError))
    }

    public notificationClicked(notificationId: number): Observable<any> {
      return this.authHttp.put("rest/user/notifications/clicked/" + notificationId, null)
        .pipe(
          map(res => {
              return res;
          }),
          catchError(this.handleError))
    }

    private handleError(error: any): Observable<any> {
      this.log.er(error);
      return throwError(() => new Error(error.message || 'Server error'));
  }

}
