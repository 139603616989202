import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pageable } from 'app_code/app/shared/interfaces/pageable';
import { SubscribedArea } from 'app_code/app/shared/interfaces/subscribed-area';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class SubscribedAreaService {

    public openModalSubject: Subject<any> = new Subject<any>();
    public comments: Comment[] = new Array<Comment>();
    public page: Pageable;
    public commentSelected = new Subject<number>();

    constructor(private authHttp: HttpClient) { }

    public addAreaSubscription(subscribedArea: SubscribedArea): Observable<SubscribedArea> {
        return this.authHttp.post('rest/area/subscribe', subscribedArea)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public getAllSubscribedAreas() : Observable<SubscribedArea[]> {
        return this.authHttp.get('rest/area/subscribe')
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError))
    }

    public deleteSubscribedArea(id: number): Observable<void> {
        return this.authHttp.delete('rest/area/subscribe/' + id)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError))
    }

    public updateSubscribedArea(id: number, name: string): Observable<void> {
        return this.authHttp.put('rest/area/subscribe', {id, name})
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError))
    }

    private handleError(error: any): Observable<any> {
        return throwError(error.message || 'Server error');
    }
}
