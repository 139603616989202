import { Router, ParamMap } from '@angular/router';
import { Log } from 'ng2-logger/browser';
import { Injectable, NgZone } from '@angular/core';
import { myRxStompConfig } from './rx-stomp.config';
import { JwtToken } from '../../interfaces/jwt-token';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from '../../interfaces/user';
import { throwError, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { RxStompService } from '@stomp/ng2-stompjs';
import { AccountStatus } from 'app_code/app/shared/interfaces/account-status';
import { ToastService } from 'app_code/app/shared/services/toast/toast.service';
import { ToastMessageType } from 'app_code/app/shared/enums/toast-message-type';

@Injectable()
export class AuthService {

  private log = Log.create('AuthenticationService');
  private tokenId: string = "id_token";
  public redirectUrl: string;
  public redirectParams: ParamMap;
  public user: User = null;
  private jwtHelper: JwtHelperService = new JwtHelperService();
  public userChanged = new BehaviorSubject<User>(this.user);
  private url: string = 'rest/';

  constructor(private rxStompService: RxStompService,
              private router: Router,
              private ngZone: NgZone,
              private toastService: ToastService,
              private authHttp: HttpClient,
              private cookieService: CookieService) {
  }

  get isLoggedIn(): boolean {
    return this.user?.id !== '0';
  }

  get userChanged$() {
    return this.userChanged.asObservable();
  }

  public login(token: JwtToken) {
    this.log.data("login(token: JwtToken)", token);
    if (this.isJwtValid(token)) {
      localStorage.setItem(this.tokenId, JSON.stringify(token));
      this.setLoggedInUser();
      this.setCookiesAgreement();
    }
  }

  public loginOnStartup(): void {
    this.setLoggedInUser();
    this.setCookiesAgreement();
  }

  public logout(): void {
    this.authHttp.delete<JwtToken>("rest/logout")
    .toPromise()
    .then(resp => {
      this.removeToken();
      console.log('Response from login - ', resp.token)
      this.login(resp)
      if(this.router.routerState.snapshot.url.split('?')[0] === '/') {
        location.reload();
      } else {
        this.router.navigate(['']);
      }
    });
    
  }

  public setLoggedInUser(): void {
    let jwtToken: JwtToken = this.getToken();
    if(!this.isJwtValid(jwtToken)) {
      this.logout();
    }
    let decodedToken = this.jwtHelper.decodeToken(jwtToken.token);
    this.user = new User();
    this.user.id = decodedToken.sub;
    this.user.roles = decodedToken.scopes;
    this.user.profileImage = jwtToken.image;
    this.user.background = jwtToken.background;
    this.user.email = jwtToken.email;
    this.user.emailConfirmed = jwtToken.emailConfirmed;
    this.user.username = jwtToken.username;
    this.user.accStatus = AccountStatus[decodedToken.accStatus as keyof typeof AccountStatus];
    this.log.data("setLoggedInUser() -> decoded token", decodedToken);
    if (this.isAccountActive()) {
      this.connectToWS(jwtToken.token);
    }
    this.userChanged.next(this.user);
  }

  private connectToWS(token: string) : void {
    myRxStompConfig.connectHeaders = { Authorization: "Bearer " + token }
    this.rxStompService.configure(myRxStompConfig);
    this.rxStompService.activate();
  }

  private setCookiesAgreement(): void {
    let cookie = 'c_' + this.user.id;
    if(!this.cookieService.check(cookie)) {
      this.cookieService.set(cookie, 'true', 180, '/');
    }
  }

  public isJwtValid(token: JwtToken) : boolean {
    this.log.data("isJwtValid(token: string)", "token: " + token);
    let resultToken: JwtToken = token;
    if(!resultToken) {
      resultToken = this.getToken();
    }
    this.log.data("isJwtValid(token: string)", "resultToken: " + resultToken);
    return resultToken != null && !this.jwtHelper.isTokenExpired(resultToken.token, this.getUtcDate());
  }

  public getUser(): User {
    return this.user;
  }

  public getUserId(): string {
    return this.user.id;
  }

  public getUsername(): string {
    return this.user.username;
  }

  public getEmail(): string {
    return this.user.email;
  }

  public getImage(): string {
    return this.user.profileImage;
  }

  public getAuthHeader(): any {
    return { 'Authorization': 'Bearer ' + localStorage.getItem(this.tokenId) };
  }

  public getToken(): JwtToken {
    let token = localStorage.getItem(this.tokenId);
    if (token) {
        return JSON.parse(token) as JwtToken;
    } else {
        return null;
    }
  }

  public isAccountActive(): boolean {
    if(!this.user) {
      return false;
    }
    return this.user.isAccountActive();
  }

  public isAccountStatus(status: String) : boolean {
    return this.user.accStatus == status;
  }

  public isUserTypeAdmin() : boolean {
    return this.user.roles.some(role => role === "ADMIN")
  }

  public showConfirmAccountMessage() : void {
    this.toastService.open(ToastMessageType.info,'activate_account.activate_info');
  }

  public hasRole(role: string): boolean {
    return this.user.roles.indexOf(role) > -1;
  }

  public redirectLoggedInUser(): void {
    if (this.isJwtValid(null)) {
      this.ngZone.run(() => {
        this.router.navigate(['']);
      })

    }
  }

  public removeToken(): void {
    localStorage.removeItem(this.tokenId);
    this.rxStompService.deactivate();
  }

  public getRedirectParamsAsMap(): any {
    let result: any = {};
    this.redirectParams?.keys?.forEach((value, index) => {
      result[value] = this.redirectParams.get(value);
    });
    this.log.data("redirect params: ", result);
    return result;
  }

  private handleError(error: any) {
    let errMsg = (error.message) ? error.message :
        error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    if (errMsg === '400 - OK' || errMsg === '401 OK') {
        errMsg = 'Invalid username/password';
    }
    return throwError(errMsg);
  }

  private getUtcDate() : number {
    return new Date().getUTCDate();
  }

}
