import { Injectable, OnInit } from "@angular/core";
import { Log } from "ng2-logger/browser";
import { HttpClient } from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class FeedbackService implements OnInit {

    private log = Log.create('FeedbackService');
    
    constructor(private http: HttpClient) {}
    
    ngOnInit(): void {}

    public sendFeedback(message: String) {
        return this.http.post('rest/feedback', {message})
            .pipe(
                map(res => {
                    return "ok";
                }),
                catchError(this.handleError)
            )
    }

    private handleError(error: any): Observable<never> {
        this.log.er(error);
        return throwError(() => new Error(error.message || 'Server error'));
    }
}
