import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, ComponentRef } from '@angular/core';
import { SpaceShoutToastComponent } from '../../components/toast/space-shout-toast.component';
import { ToastMessageType } from '../../enums/toast-message-type';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    private readonly TOAST_DEFAULT_OPEN_TIME = 6500;

    private toastComponentRef: ComponentRef<SpaceShoutToastComponent>;
    private subscriptions = new Array<Subscription>();
    private toastTimeoutId = null;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector) {}

    open(type: ToastMessageType, message: string) {
        if (this.toastComponentRef) {
            this.close();
        }
        // Create component dynamically
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SpaceShoutToastComponent);
        this.toastComponentRef = componentFactory.create(this.injector);
        this.toastComponentRef.instance.type = type;
        this.toastComponentRef.instance.message = message;

        this.subscriptions.push(
            this.toastComponentRef.instance.shoutHide.subscribe(() => {
                this.close();
            })
        );
        // Attach component to the appRef so that it's inside the ng component tree
        this.appRef.attachView(this.toastComponentRef.hostView);

        // Get DOM element from the component
        const domElem = (this.toastComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

        // Append DOM element to the body
        document.body.appendChild(domElem);

        // Automatically close the toast after TOAST_DEFAULT_OPEN_TIME seconds
        this.toastTimeoutId = setTimeout(() => {
            this.close();
        }, this.TOAST_DEFAULT_OPEN_TIME);
    }

    close() {
        if (this.toastTimeoutId) {
            clearTimeout(this.toastTimeoutId);
            this.toastTimeoutId = null;
        }
        if (this.toastComponentRef) {
            // Detach the view from the application
            this.appRef.detachView(this.toastComponentRef.hostView);

            // Clear subscriptions
            this.subscriptions.forEach((sub) => sub.unsubscribe());
            this.subscriptions = [];

            // Destroy the component
            this.toastComponentRef.destroy();
            this.toastComponentRef = null;
        }
    }
}
