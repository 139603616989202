import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JwtToken } from "app_code/app/shared/interfaces/jwt-token";
import { Observable, throwError } from "rxjs";
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class InitializationService {

    constructor(private http: HttpClient) {}

    public initialize(): Promise<any> {
        return this.http.get<JwtToken>("rest/token/check/")
            .pipe(
                map((resp: JwtToken) => {
                    if (resp !== null) {
                        localStorage.setItem("id_token", JSON.stringify(resp));
                    }
                    console.log("initialize()", resp);
                }),
                catchError(this.handleError.bind(this))
            ).toPromise();
    }
    
    private handleError(error: any): Observable<never> {
        console.error('Initialization error', error);
        return throwError(error);
    }
}