import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastMessageType } from '../../enums/toast-message-type';

@Component({
  selector: 'space-shout-toast',
  templateUrl: './space-shout-toast.component.html',
  styleUrls: ['./space-shout-toast.component.scss']
})
export class SpaceShoutToastComponent {

  @Input() type: ToastMessageType;

  @Input() message = '';

  @Input() closable = true;

  @Output() shoutHide = new EventEmitter();

  constructor() {}

  onHide(): void {
    this.shoutHide.emit();
  }
}
