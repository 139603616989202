import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { JwtToken } from '../../../../shared/interfaces/jwt-token';
import { Log } from 'ng2-logger/browser';
import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { AuthService } from 'app_code/app/shared/services/auth/auth.service';
import { MixpanelService } from 'app_code/app/shared/services/login/mixpanel.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { switchMap, take } from 'rxjs/operators';
import { ModalsService } from '../modals.service';
import { Router } from '@angular/router';
import { ToastService } from 'app_code/app/shared/services/toast/toast.service';
import { ToastMessageType } from 'app_code/app/shared/enums/toast-message-type';


@Injectable()
export class LoginService {

    loading = false;

    private log = Log.create('LoginService');

    constructor(
        private recaptchaV3Service: ReCaptchaV3Service,
        private authService: AuthService,
        private toastService: ToastService,
        private mixpanelService: MixpanelService,
        private socialAuthService: SocialAuthService,
        private modalService: ModalsService,
        private router: Router,
        private http: HttpClient,
        private cd: ChangeDetectorRef
    ) { }

    logIn(email: string, password: string): void {
        this.loading = true;
        this.recaptchaV3Service.execute('login').pipe(
            take(1),
            switchMap((captcha) => this.login(email, password, captcha))
        ).subscribe(
            (token: JwtToken) => {
                this.postLogin(token, 'spaceshout');
                this.loading = false;
                this.cd.markForCheck();
            }, (error) => {
                this.toastService.open(ToastMessageType.error, 'login.error.wrong_credentials');
                this.log.data('login captcha', error);
                this.loading = false;
                this.cd.markForCheck();
            }
        );
    }

    logInThroughSocial(socialUser: SocialUser): void {
        this.loading = true;
        this.log.data('Social user: ', socialUser);
        this.recaptchaV3Service.execute('register').pipe(
            take(1),
            switchMap((captcha) => {
                const token = socialUser.provider === 'FACEBOOK' ? socialUser.authToken : socialUser.idToken;
                return this.socialLogin(token, socialUser.provider, captcha);
            })
        ).subscribe((token: JwtToken) => {
            this.postLogin(token, socialUser.provider);
            this.loading = false;
            this.cd.markForCheck();
        }, (error) => {
            this.toastService.open(ToastMessageType.error, 'global.error');
            this.log.data('social login error', error);
            this.socialAuthService.signOut();
            this.loading = false;
            this.cd.markForCheck();
        });
    }

    postLogin(token: JwtToken, loginMethod: string): void {
        this.authService.login(token);
        this.mixpanelService.login(loginMethod);

        this.navigateAfterLoggedIn();
    }

    private login(email: string, password: string, captcha: string): Observable<JwtToken> {
        let params = new HttpParams().set('captcha', captcha).set('action', 'login')

        return this.http.post('/rest/login', { email: email, password: password }, { params: params })
            .pipe(
                map((res: JwtToken) => res),
                catchError(this.handleError))
    }

    private socialLogin(token: string, provider: string, captcha: string): Observable<any> {
        let params = new HttpParams().set('captcha', captcha).set('action', 'login')

        return this.http.post('/rest/login/social', { token: token, provider: provider }, { params: params })
            .pipe(
                map((res: any) => {
                    return res;
                }),
                catchError(this.handleError))
    }

    private navigateAfterLoggedIn(): void {
        this.modalService.close();
        if (this.authService.redirectUrl) {
            this.router.navigate([this.authService.redirectUrl], { queryParams: this.authService.getRedirectParamsAsMap() })
        } else {
            this.router.navigate(['']);
        }
    }

    private handleError(error: any) {
        let errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        if (errMsg === '400 - OK' || errMsg === '401 OK') {
            errMsg = 'Invalid username/password';
        }
        return throwError(errMsg);
    }
}
