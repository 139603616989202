import { ActivateAccountComponent } from './../ui/activate-account/activate-account.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './route-guards/auth-guard.service';
import { AdminGuard } from './route-guards/admin-guard.service';
import { PasswordResetGuard } from './route-guards/password-reset-guard.service';
import { MapParamsResolver } from './resolvers/map-query-params.resolver';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../ui/map/map.module').then(m => m.MapModule),
    canActivate: [PasswordResetGuard],
    resolve: { mapParams: MapParamsResolver}
  },
  {
    path: 'subscribed-areas',
    loadChildren: () => import('../ui/subscribed-areas/subscribed-areas.module').then(m => m.SubscribedAreasModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'collections',
    loadChildren: () => import('../ui/collections/collections-page/collections.module').then(m => m.CollectionsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'terms-of-service',
    loadChildren: () => import('../ui/policies/terms-of-service/terms-of-service.module').then(m => m.TermsOfServiceModule),
  },
  {
    path: 'privacy',
    loadChildren: () => import('../ui/policies/privacy/privacy.module').then(m => m.PrivacyModule),
  },
  {
    path: 'activate',
    component: ActivateAccountComponent
  },
  {
    path: 'admin',
    loadChildren: () => import('../ui/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AdminGuard]
  },
  {
    path: '', 
    redirectTo: '/', 
    pathMatch: 'full' 
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
