<div 
    class="toast" 
    [attr.type]="type"
>
    <ng-content select="[prefix]"></ng-content>

    {{ message | translate }}

    <spaceshout-button
        *ngIf="closable"
        [priority]="'senary'"
        [size]="'roundedSmall'"
        [icon]="'closeCircleFilled'"
        (clicked)="onHide('')"
    ></spaceshout-button>

</div>