import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SearchType } from 'app_code/app/shared/interfaces/search-type.enum';
import { PointContentDTO } from 'app_code/app/shared/model/point-dto';

@Component({
  selector: 'shout-text-point',
  templateUrl: './text-point.component.html',
  styleUrls: ['./text-point.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextPointComponent {

  @Input() point: PointContentDTO;
  @Input() searchType: SearchType;
  @Input() hasSelectedItem: boolean;
  @Input() isCollectionHintVisible: boolean;
  @Input() isWelcome: boolean;
  @Input() description: string;

  @Output() clicked = new EventEmitter<void>();
  @Output() wheeled = new EventEmitter<WheelEvent>();

  SearchType = SearchType;

  onPointClick(): void {
    this.clicked.emit();
  }

  onWheel(ev: WheelEvent): void {
    this.wheeled.emit(ev);
  }
}
