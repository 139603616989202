import { Log } from 'ng2-logger/browser';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Reply } from '../../interfaces/reply';
import { Observable, throwError } from 'rxjs';
import { Injectable } from "@angular/core";
import { ReplyContentDTO, ReplyDTO } from 'app_code/app/shared/model/reply-dto';


@Injectable()
export class ReplyService {

    private log = Log.create('ReplyService');

    constructor(private authHttp: HttpClient) { }

    public getRepliesByCommentId(commentId: number, page: number, pageSize: number): Observable<ReplyDTO> {
        return this.authHttp.get('rest/poi/' + commentId + '/replies?size=' + pageSize + '&page='+ page)
        .pipe(
            map(res => {
                return res;
            }), 
            catchError(this.handleError))
    }

    public addReply(commentId: number, images: Array<string>, text: string): Observable<ReplyContentDTO> {
        let reply: any = {
            text: text,
            images: images,
            poiId: commentId
        };
        return this.authHttp.post("rest/poi/replies", reply)
        .pipe(
            map(res => {
                return res;
            }), 
            catchError(this.handleError))
    }

    public updateReply(reply: ReplyContentDTO) : Observable<Reply> {
        let replyUpdate: any = {
            id: reply.id,
            text: reply.text,
        }
        return this.authHttp.put("rest/poi/replies", replyUpdate)
        .pipe(
            map(res => {
                return res;
            }), 
            catchError(this.handleError))
    }

    public deleteReply(replyId: number) : Observable<void> {
        return this.authHttp.delete("rest/poi/replies/" + replyId)
        .pipe(
            map(res => {
                return res;
            }), 
            catchError(this.handleError))
    }

    public addLike(replyId: number): Observable<void> {
        return this.authHttp.post('rest/poi/replies/' + replyId + '/likes', null)
        .pipe(
            map(res => {
                return res;
            }), 
            catchError(this.handleError))
    }

    public deleteLike(replyId: number): Observable<void> {
        return this.authHttp.delete('rest/poi/replies/' + replyId + '/likes')
        .pipe(
            map(res => {
                return res;
            }), 
            catchError(this.handleError))
    }

    private handleError(error: any): Observable<any> {
        this.log.data(error);
        return throwError(error.message || 'Server error');
    }
}